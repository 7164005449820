<template>
    <v-container fluid class="mx-0 px-3">
        <v-card flat class="avsb-card-background">
            <v-card-title class="pl-1 py-2">Clients</v-card-title>
        </v-card>

        <!-- Tables -->
        <TableClients />

        <!-- Dialogs -->
        <DialogClientCreate />
    </v-container>
</template>

<script>
export default {
    components: {
        DialogClientCreate: () =>
            import("@/components/admin/dialogs/dialogClientCreate"),
        TableClients: () => import("@/components/admin/tables/tableClients"),
    },
    methods: {},
    name: "Clients",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
